import currenciesArray from './currencies.json';
export { default as CURRENCIES_ARRAY } from './currencies.json';

export const CURRENCIES = currenciesArray.reduce((currencies, currency) => {
  currencies[currency.id] = currency;
  return currencies;
}, {});

export const CURRENCIES_IDS = currenciesArray.map(({ id }) => id);

export const CURRENCIES_IDS_MAP = currenciesArray.reduce((currencies, currency) => {
  currencies[currency.id] = currency.id;
  return currencies;
}, {});

export const DEFAULT_CURRENCY = CURRENCIES_IDS_MAP.USD;

export function getCurrencySign(currency) {
  return CURRENCIES[currency || DEFAULT_CURRENCY].symbol;
}

export const LOCALE_MAP = {
  AFN: 'ps-AF',
  ALL: 'sq-AL',
  DZD: 'ar-DZ',
  AOA: 'pt-AO',
  ARS: 'es-AR',
  AMD: 'hy-AM',
  AUD: 'en-AU',
  AZN: 'az-AZ',
  BHD: 'ar-BH',
  BDT: 'bn-BD',
  BYR: 'be-BY',
  BZD: 'en-BZ',
  BOB: 'es-BO',
  BAM: 'bs-BA',
  BWP: 'en-BW',
  BRL: 'pt-BR',
  GBP: 'en-GB',
  BND: 'ms-BN',
  BGN: 'bg-BG',
  BIF: 'fr-BI',
  KHR: 'km-KH',
  CAD: 'en-CA',
  CVE: 'pt-CV',
  XAF: 'fr-CM',
  CLP: 'es-CL',
  CNY: 'zh-CN',
  COP: 'es-CO',
  KMF: 'ar-KM',
  CDF: 'fr-CD',
  CRC: 'es-CR',
  HRK: 'hr-HR',
  CZK: 'cs-CZ',
  DKK: 'da-DK',
  DJF: 'ar-DJ',
  DOP: 'es-DO',
  EGP: 'ar-EG',
  ERN: 'ti-ER',
  ETB: 'am-ET',
  EUR: 'de-DE',
  FJD: 'en-FJ',
  GMD: 'en-GM',
  GEL: 'ka-GE',
  GHS: 'en-GH',
  GTQ: 'es-GT',
  GNF: 'fr-GN',
  HNL: 'es-HN',
  HKD: 'zh-HK',
  HUF: 'hu-HU',
  ISK: 'is-IS',
  INR: 'hi-IN',
  IDR: 'id-ID',
  IRR: 'fa-IR',
  IQD: 'ar-IQ',
  ILS: 'he-IL',
  JMD: 'en-JM',
  JPY: 'ja-JP',
  JOD: 'ar-JO',
  KZT: 'kk-KZ',
  KES: 'sw-KE',
  KWD: 'ar-KW',
  KGS: 'ky-KG',
  LAK: 'lo-LA',
  LBP: 'ar-LB',
  LSL: 'st-LS',
  LRD: 'en-LR',
  LYD: 'ar-LY',
  MOP: 'zh-MO',
  MKD: 'mk-MK',
  MGA: 'mg-MG',
  MWK: 'ny-MW',
  MYR: 'ms-MY',
  MVR: 'dv-MV',
  MRO: 'ar-MR',
  MUR: 'en-MU',
  MXN: 'es-MX',
  MDL: 'ro-MD',
  MNT: 'mn-MN',
  MAD: 'ar-MA',
  MZM: 'pt-MZ',
  MMK: 'my-MM',
  NAD: 'en-NA',
  NPR: 'ne-NP',
  TWD: 'zh-TW',
  NZD: 'en-NZ',
  NIO: 'es-NI',
  NGN: 'en-NG',
  KPW: 'ko-KP',
  NOK: 'nb-NO',
  OMR: 'ar-OM',
  PKR: 'ur-PK',
  PAB: 'es-PA',
  PGK: 'en-PG',
  PYG: 'es-PY',
  PEN: 'es-PE',
  PHP: 'fil-PH',
  PLN: 'pl-PL',
  QAR: 'ar-QA',
  RON: 'ro-RO',
  RUB: 'ru-RU',
  RWF: 'rw-RW',
  SAR: 'ar-SA',
  RSD: 'sr-RS',
  SCR: 'en-SC',
  SGD: 'zh-SG',
  SBD: 'en-SB',
  SOS: 'so-SO',
  ZAR: 'en-ZA',
  KRW: 'ko-KR',
  LKR: 'si-LK',
  SDG: 'ar-SD',
  SRD: 'nl-SR',
  SZL: 'en-SZ',
  SEK: 'sv-SE',
  CHF: 'de-CH',
  SYP: 'ar-SY',
  TJS: 'tg-TJ',
  TZS: 'sw-TZ',
  THB: 'th-TH',
  TOP: 'to-TO',
  TTD: 'en-TT',
  TND: 'ar-TN',
  TRY: 'tr-TR',
  TMT: 'tk-TM',
  UGX: 'sw-UG',
  UAH: 'uk-UA',
  AED: 'ar-AE',
  USD: 'en-US',
  UYU: 'es-UY',
  UZS: 'uz-UZ',
  VUV: 'bi-VU',
  VEB: 'es-VE',
  VND: 'vi-VN',
  YER: 'ar-YE',
  ZMK: 'en-ZM',
  ZWD: 'en-ZW'
};
