import React, { useEffect, useMemo, useState } from 'react';
import { sortBy, filter, find, partition, isNil } from 'lodash';
import { Grid } from '@material-ui/core';
import { useQuery } from 'react-apollo';
import { makeStyles } from '@material-ui/core';
import StarRatingComponent from 'react-star-rating-component';
import clsx from 'clsx';
import color from 'color';

import { getTapLocation, groupTaps } from '../../../util/tap';
import { sortServings } from '../../../util/servings';

import { GET_TAPS_BY_STATUS, GET_TAP_STATUS } from '../queries';

import { FeedbackBar } from '../../../components/FeedbackBar';
import { useWebSocket } from '../../../contexts/webSockets';

import './styles.css';
import { t } from 'i18next';
import { UserVesselsProvider } from '../../../contexts/userVessels';
import { Serving } from './Serving';
import { TAP_ORIENTATION } from '../../../util/enums';
import { sortTaps } from '../../taps/print/utils';

const POLLING_INTERVAL = 1000 * 60; // 1 minute in milliseconds

const useStyles = makeStyles(() => ({
  container: {
    // flex: 1,
    overflow: 'hidden',
    width: '100%'
    // display: 'flex'
  },
  full: {
    width: '100%',
    height: '100%'
  },
  backdrop: {
    background: 'rgba(0,0,0,0.4)'
  }
}));

function getContainerStyle(theme, screen) {
  const baseStyle = theme ? { color: theme.color } : {};

  const withBackgroundColor = theme && theme.backgroundColor ? { ...baseStyle, backgroundColor: theme.backgroundColor } : baseStyle;

  const withBackgroundImage =
    theme && theme.backgroundImageUrl
      ? { ...withBackgroundColor, backgroundImage: `url(${theme.backgroundImageUrl})`, backgroundSize: 'cover' }
      : withBackgroundColor;

  const isSmallScreen = screen?.device?.type === 'chromecast' || document.documentElement.clientWidth <= 1300;
  const fontSizeMap = isSmallScreen
    ? { condensed: '0.4rem', medium: '0.6rem', normal: '0.7rem', lg: '0.9rem', xl: '1rem', xl2: '1.05rem', xl3: '1.1rem', xxl: '1.2rem' }
    : { condensed: '0.8rem', medium: '1rem', normal: '1.1rem', lg: '1.3rem', xl: '1.5rem', xl2: '1.55rem', xl3: '1.7rem', xxl: '1.8rem' };

  const fontSize = fontSizeMap[theme?.textSize || 'normal'];

  const themeScale = (value) => {
    const textSize = theme?.textSize || 'normal';
    if (textSize === 'condensed') return value * 0.9;
    if (textSize === 'medium') return value * 1.1;
    if (textSize === 'normal') return value * 1.3;
    if (textSize === 'xl') return value * 1.4;
    if (textSize === 'xl2') return value * 1.5;
    if (textSize === 'xl3') return value * 1.6;
    if (textSize === 'xxl') return value * 2;
  };

  return [{ ...withBackgroundImage, fontSize }, isSmallScreen, themeScale];
}

export const TapPage = ({ page, screen }) => {
  const classes = useStyles();
  const { location } = screen;

  const [sortedTaps, setSortedTaps] = useState([]);
  const [[activeTaps, onDeckTaps], setTapLists] = useState([[], []]);

  const { error, data, refetch } = useQuery(GET_TAPS_BY_STATUS, {
    variables: { location: location?._id, statuses: ['ACTIVE', 'ON_DECK'] },
    fetchPolicy: 'cache-and-network'
  });
  const {
    error: statusError,
    data: statusData,
    refetch: refetchTapStatus
  } = useQuery(GET_TAP_STATUS, {
    variables: { screen: screen._id, page: page._id },
    fetchPolicy: 'cache-and-network'
  });

  const tapList = useMemo(() => data?.taps || [], [data?.taps]);
  const tapStatusList = statusData?.tapStatus || [];

  const { tapStart, tapEnd } = page;

  let theme = page.style?.themeOptions || location?.settings?.themeOptions || {};
  if (page.style?.pageKey) {
    // see if we can find the page this one references...
    theme = find(screen.pages, (p) => p.key === page.style.pageKey)?.style?.themeOptions || theme;
  }

  const { webSocketEventEmitter, isWebSocketConnected } = useWebSocket();

  useEffect(() => {
    const intervals = [];

    if (!isWebSocketConnected) {
      console.log('Adding backup tap list/status polling intervals');
      const tapListRefetchInterval = setInterval(async () => {
        refetch();
      }, POLLING_INTERVAL);
      intervals.push(tapListRefetchInterval);

      const tapStatusRefetchInterval = setInterval(async () => {
        refetchTapStatus();
      }, POLLING_INTERVAL);
      intervals.push(tapStatusRefetchInterval);
    } else {
      console.log('WebSocket is available. Tap status polling disabled.');
    }

    return () => {
      console.log('Removing backup polling intervals for taps');
      intervals.map(clearInterval);
    };
  }, [refetch, refetchTapStatus, isWebSocketConnected]);

  useEffect(() => {
    let subscription;

    if (isWebSocketConnected) {
      console.log('Adding Tap Update Listener');
      subscription = webSocketEventEmitter.subscribe('TAP_UPDATE', () => {
        console.log('WebSocket event received. Refetching tap list and tap status...');
        try {
          refetch();
          refetchTapStatus();
          console.log('Refetching complete.');
        } catch (err) {
          console.log('refresh error', err);
        }
      });
    }

    return () => {
      if (subscription) {
        console.log('Removing Tap Update Listener');
        subscription.unsubscribe();
      }
    };
  }, [webSocketEventEmitter, refetch, refetchTapStatus, isWebSocketConnected]);

  useEffect(() => {
    if (tapList?.length > 0) {
      console.log('tap list has changed...');
      let filteredTaps = sortBy(
        filter(tapList, (t) => t.name >= tapStart && t.name <= tapEnd),
        (t) => t.number
      );
      let sorted = sortBy(filteredTaps, (t) => t.name);
      setSortedTaps(sorted);
    }
  }, [tapList, tapStart, tapEnd]);

  useEffect(() => {
    if (data?.taps) {
      const [active, onDeck] = partition(data.taps, (t) => t.status === 'ACTIVE');

      let sortedActive = active;
      if (page.sortBy) {
        sortedActive = sortTaps(active, page.sortBy);
      } else {
        sortedActive = sortBy(active, (t) => t.name);
      }

      let processedActive = sortedActive;
      if (page.groupBy && page.groupBy !== 'none') {
        const groups = groupTaps(sortedActive, page.groupBy, t);

        processedActive = [];
        Object.entries(groups).forEach(([groupName, tapsInGroup]) => {
          tapsInGroup.forEach((tap, index) => {
            processedActive.push({
              ...tap,
              _groupName: groupName,
              _isFirstInGroup: index === 0
            });
          });
        });
      }

      setSortedTaps(sortedActive);
      setTapLists([processedActive, onDeck]);
    }
  }, [data, page.sortBy, page.groupBy]);

  const [containerStyle, isSmallScreen, themeScale] = getContainerStyle(theme, screen);
  const screenSizeClass = isSmallScreen ? 'small_screen' : 'large_screen';

  let contrastColor = color(theme.backgroundColor).isLight() ? color('black') : color('white');
  let isTopToBottom = theme.tapsOrientation === TAP_ORIENTATION.TOP_TO_BOTTOM;
  console.log('TAPS', onDeckTaps, activeTaps);
  return (
    <UserVesselsProvider>
      <div className={clsx(classes.container)} style={containerStyle}>
        <div className={clsx(classes.container, classes.full, theme?.showBackgroundImageHelper ? classes.backdrop : undefined)}>
          <FeedbackBar message={error || statusError} />
          {theme?.showHeading && (
            <h1 className={`page-heading ${screenSizeClass}`} style={{ color: theme.foregroundColor }}>
              {page.name}
            </h1>
          )}
          <div className={clsx('inventory-grid', isTopToBottom ? TAP_ORIENTATION.TOP_TO_BOTTOM : undefined)}>
            {activeTaps.map((t, i) =>
              renderItem(
                page,
                t,
                i + 1,
                sortedTaps,
                theme,
                find(tapStatusList, (ts) => ts.tapName === t.name?.toString()),
                isSmallScreen,
                themeScale
              )
            )}
          </div>

          {onDeckTaps.length > 0 && (
            <div className="inventory-grid">
              <h1 className="section-title" style={{ color: contrastColor }}>
                {t('Preview.OnDeck')}
              </h1>
              {onDeckTaps.map((t, i) =>
                renderItem(
                  page,
                  t,
                  i + 1,
                  sortedTaps,
                  theme,
                  find(tapStatusList, (ts) => ts.tapName === t.name?.toString()),
                  isSmallScreen,
                  themeScale
                )
              )}
            </div>
          )}
        </div>

        {/*page.customCss && <style type="text/css">{page.customCss}</style>*/}
        {/*customCss && <style dangerouslySetInnerHTML={{ __html: customCss }} /> */}
      </div>
    </UserVesselsProvider>
  );
};

const renderItem = (page, tap, tabIdx, allTaps, theme, tapStatus, isSmallScreen, themeScale) => {
  const screenSizeClass = isSmallScreen ? 'small_screen' : 'large_screen';
  let itemStyle = {};
  if (theme?.columns === 1) {
    itemStyle.width = '100%';
    itemStyle.paddingRight = '60px';
  } else if (theme?.columns === 2) {
    itemStyle.width = '50%';
    if (tabIdx % 2 === 0) itemStyle.paddingRight = '60px';
  } else if (theme?.columns === 3) {
    itemStyle.width = '33.33%';
    if (tabIdx % 3 === 0) itemStyle.paddingRight = '60px';
  }

  let contrastColor = color(theme.backgroundColor).isLight() ? color('black') : color('white');

  if (theme.showStripes) {
    const altColor = contrastColor.alpha(0.1);

    let index = allTaps.indexOf(tap);
    let row = index / theme.columns;
    const isAlt = page.style?.themeOptions?.tapsOrientation === TAP_ORIENTATION.TOP_TO_BOTTOM ? index % 2 === 1 : Math.floor(row) % 2 === 1;
    if (isAlt) {
      itemStyle.backgroundColor = `rgba(${altColor.red()}, ${altColor.green()}, ${altColor.blue()}, ${altColor.alpha()})`;
    }
  }

  const imageUrl = (find(tap.beverage.images, (i) => i.size === 'medium') || {}).url;

  let kegStatusClassName = tapStatus
    ? tapStatus.percentRemaining * 100 > 50
      ? 'full'
      : tapStatus.percentRemaining * 100 > 30
      ? 'mid'
      : 'empty'
    : '';

  const tapLocation = getTapLocation(tap);
  const abv = isNil(tap.beverage.abv) ? '?' : tap.beverage.abv;
  const ibu = isNil(tap.beverage.ibu) ? '?' : tap.beverage.ibu;

  const beverageImageSize = theme.beverageImageSize || page.style?.themeOptions?.beverageImageSize;
  const imageScale = (value) => (!!beverageImageSize && beverageImageSize.includes('large') ? value * 1.3 : value);

  const imageSize = isSmallScreen ? {} : { width: themeScale(imageScale(70)), height: themeScale(imageScale(70)) };

  return (
    <div key={tap._id} style={itemStyle}>
      {tap._isFirstInGroup && tap._groupName && (
        <div
          className="group-title-container"
          style={{
            color: theme.foregroundColor,
            width: itemStyle.width,
            borderBottomColor: theme.foregroundColor
          }}>
          {tap._groupName}
        </div>
      )}

      <div
        className={`item-meta ${color(theme.backgroundColor).isLight() ? 'light' : 'dark'} ${screenSizeClass} ${
          page.groupBy ? 'item-meta-with-group' : ''
        }`}
        key={tap._id}
        style={{
          paddingBottom: page.groupBy ? '5px' : 'unset'
        }}>
        {theme.showTapNumber && (
          <span
            className={`big-handle ${screenSizeClass}`}
            style={{
              color: theme.foregroundColor,
              fontFamily: theme.primaryFont
            }}>
            {tap.name}
          </span>
        )}

        {theme.showBeverageImage && (
          <span
            className={`item-image ${beverageImageSize} ${screenSizeClass}`}
            style={{ backgroundImage: `url("${imageUrl}")`, ...imageSize }}></span>
        )}

        {theme.kegMonitoringPartner !== 'none' && tapStatus && (
          <div className="item-meta-keg-status">
            <div className={`progress-bar ${kegStatusClassName}`} style={{ height: `${tapStatus.percentRemaining * 100}%` }} />
          </div>
        )}

        <div className="item-meta-description">
          <div className={`item-meta-primary ${theme.menuType || ''} ${theme.reverseBreweryAndName ? 'reversed' : ''}`}>
            <div
              className="item-name"
              style={{
                color: theme.foregroundColor2,
                fontFamily: theme.primaryFont,
                marginRight: theme.reverseBreweryAndName ? '10px' : undefined
              }}>
              {tap.beverage.name}
            </div>
            {!theme.hideBeverageSourceName && (
              <div
                className={`item-source ${theme.menuType || ''} single-line`}
                style={{
                  color: theme.foregroundColor,
                  fontFamily: theme.secondaryFont
                }}>
                {tap?.beverage?.source?.name}
              </div>
            )}
          </div>

          <div
            className={`item-meta-secondary padding-bottom-x2 ${theme.menuType || ''} single-line-${screenSizeClass}`}
            style={{
              color: theme.foregroundColor,
              fontFamily: theme.secondaryFont
            }}>
            <span className="style">{tap.beverage.style}</span>
            {theme.showLocation && tapLocation && (
              <>
                <span className="divider"> - </span>
                <span className="style">{tapLocation}</span>
              </>
            )}
            {tap.showABV !== false && (
              <>
                <span className="divider"> - </span>
                <span className="abv">{abv}%</span>
              </>
            )}
            {tap.showIBU === true && (
              <>
                <span className="divider"> - </span>
                <span className="abv">{ibu} IBUs</span>
              </>
            )}
          </div>

          {page.showRating && (
            <div className="item-rating">
              <StarRatingComponent name="rating" starCount={5} editing={false} value={tap.beverage.rateBeerRating} />
            </div>
          )}
          {theme.showTapsDescription && (
            <div
              className={`whitespace-pre-wrap item-description ${screenSizeClass}`}
              style={{
                color: theme.foregroundColor,
                fontFamily: theme.secondaryFont
              }}>
              {tap.beverage.description}
            </div>
          )}
          {theme.showServings && (
            <Grid container className="item-meta-cost">
              <Grid container item xs={12} spacing={2} style={{ textAlign: 'center' }}>
                {sortServings(tap.servings).map((s) => (
                  <Serving serving={s} theme={theme} />
                ))}
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    </div>
  );
};
