import React, { useContext, useState } from 'react';
import { isNil } from 'lodash';
import { useMutation } from 'react-apollo';
import { getServingPriceLabel, getServingSizeLabel, resolveVesselName } from '../../util/lang';
import { UserVesselsContext } from '../../contexts/userVessels';
import { UPDATE_TAP, DELETE_TAP } from './mutations';
import DropdownMenu from '../../components/DropdownMenu';
import { EditServingDialog } from './servings/EditServingDialog';
import { useTranslation } from 'react-i18next';
import { cleanCachedObjectForMutation } from '../../util/graphql';
import { GET_TAPS } from './queries';
import { Tap, Serving } from '../../types';
import { sortServings } from '../../util/servings';

interface TapListItemProps {
  tap: Tap;
  onClick: (e: React.MouseEvent | React.TouchEvent) => void;
  onUpdate: () => void;
}

interface ExistingTapsData {
  taps: Tap[];
}

export const TapListItem: React.FC<TapListItemProps> = ({ tap, onClick, onUpdate }) => {
  const { t } = useTranslation();
  const [editServing, setEditServing] = useState<Partial<Serving> | null>(null);
  const [updateTap] = useMutation(UPDATE_TAP);
  const [deleteTap] = useMutation(DELETE_TAP);
  const abv = isNil(tap.beverage?.abv) ? 'N/A' : tap.beverage?.abv;
  const ibu = tap.beverage?.ibu;
  const [isHovered, setIsHovered] = useState(false);

  const handleStatusChange = async (status: Tap['status']) => {
    const cleanTap = cleanCachedObjectForMutation({
      ...tap,
      status
    });

    await updateTap({
      variables: {
        tap: cleanTap
      }
    });
    if (onUpdate) onUpdate();
  };

  const handleDeleteTap = async () => {
    await deleteTap({
      variables: {
        id: tap._id
      },
      update: (cache) => {
        const existingTaps = cache.readQuery<ExistingTapsData>({
          query: GET_TAPS,
          variables: { location: tap.location }
        });

        if (existingTaps) {
          cache.writeQuery({
            query: GET_TAPS,
            variables: { location: tap.location },
            data: {
              taps: existingTaps.taps.filter((t) => t._id !== tap._id)
            }
          });
        }
      }
    });
    if (onUpdate) onUpdate();
  };

  const handleSaveServing = async (serving: Serving) => {
    const { isNew, ...servingData } = serving;
    const cleanServing = cleanCachedObjectForMutation(servingData);

    const cleanTap = cleanCachedObjectForMutation({
      ...tap,
      servings: [...tap.servings, cleanServing]
    });

    await updateTap({
      variables: {
        tap: cleanTap
      }
    });
    setEditServing(null);
    if (onUpdate) onUpdate();
  };

  const menuItems = [
    {
      label: t('TapList.SetActive'),
      onClick: (e: React.MouseEvent | React.TouchEvent) => {
        e.stopPropagation();
        handleStatusChange('ACTIVE');
      }
    },
    {
      label: t('TapList.SetOnDeck'),
      onClick: (e: React.MouseEvent | React.TouchEvent) => {
        e.stopPropagation();
        handleStatusChange('ON_DECK');
      }
    },
    {
      label: t('TapList.SetInactive'),
      onClick: (e: React.MouseEvent | React.TouchEvent) => {
        e.stopPropagation();
        handleStatusChange('INACTIVE');
      }
    },
    {
      label: t('TapList.AddServing'),
      onClick: (e: React.MouseEvent | React.TouchEvent) => {
        e.stopPropagation();
        setEditServing({ isNew: true });
      }
    },
    {
      label: t('TapList.Delete'),
      onClick: (e: React.MouseEvent | React.TouchEvent) => {
        e.stopPropagation();
        handleDeleteTap();
      },
      className: 'text-dark-red',
      danger: true
    }
  ];

  return (
    <>
      <>
        <div
          className="cursor-pointer py-6 px-4 md:px-6 border-0 sm:border-b border-accent-grey -mr-2 flex sm:justify-center"
          onClick={onClick}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}>
          {tap.status !== 'INACTIVE' && (
            <span className="inline-block border border-gray-300 rounded-full h-10 w-10 leading-[38px] text-center text-sm">
              {tap.name}
            </span>
          )}
        </div>
        <div
          className="hidden sm:block cursor-pointer py-6 px-4 border-b border-accent-grey -mx-2"
          onClick={onClick}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}>
          <TapDetails tap={tap} abv={abv} ibu={ibu} isHovered={isHovered} />
          <div className="w-full md:hidden mt-4">
            {sortServings(tap.servings).map((s) => (
              <BeverageServing key={s._id} serving={s} />
            ))}
          </div>
        </div>
        <div
          className="cursor-pointer hidden md:block py-6 px-4 border-b border-accent-grey -mx-2"
          onClick={onClick}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}>
          <div className="flex flex-wrap flex-col justify-start">
            {sortServings(tap.servings).map((s) => (
              <BeverageServing key={s._id} serving={s} />
            ))}
          </div>
        </div>
        <div
          className="py-6 px-4 md:px-6 border-0 sm:border-b border-accent-grey text-dark-grey flex sm:justify-center"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}>
          <DropdownMenu
            items={menuItems.filter((item) => {
              const statusMap = {
                ACTIVE: 'SetActive',
                ON_DECK: 'SetOnDeck',
                INACTIVE: 'SetInactive'
              };
              return item.label !== t(`TapList.${statusMap[tap.status]}`);
            })}
          />
        </div>

        <EditServingDialog serving={editServing} onCancel={() => setEditServing(null)} onSave={handleSaveServing} />
      </>
      <div
        className="sm:hidden col-span-2 cursor-pointer pb-6 px-4 md:px-6 border-b border-gray-200"
        onClick={onClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        <TapDetails tap={tap} abv={abv} ibu={ibu} isHovered={isHovered} />
        <div className="w-full md:hidden mt-4">
          {sortServings(tap.servings).map((s) => (
            <BeverageServing key={s._id} serving={s} />
          ))}
        </div>
      </div>
    </>
  );
};

interface TapDetailsProps {
  tap: Tap;
  abv?: string | number;
  ibu?: number;
  isHovered: boolean;
}

const TapDetails: React.FC<TapDetailsProps> = ({ tap, abv, ibu, isHovered }) => {
  return (
    <div className="w-full">
      <div
        className={`font-bold text-[1.25rem] leading-snug mb-1 transition-colors duration-200 ${isHovered ? 'text-orange' : ''} ${
          tap.status === 'INACTIVE' ? 'opacity-50' : ''
        }`}>
        {tap.beverage?.name || ''}
      </div>
      <div className="text-lg leading-tight mb-1">{tap.beverage?.source?.name || ''}</div>
      <div className="text-md leading-tight whitespace-pre-wrap opacity-70">
        {`${tap.beverage?.style || ''}  -  ABV ${abv}%${ibu ? ` - ${ibu} IBUs` : ''}`}
      </div>
    </div>
  );
};

interface BeverageServingProps {
  serving: Serving;
}

const BeverageServing: React.FC<BeverageServingProps> = ({ serving }) => {
  const { userVessels } = useContext(UserVesselsContext) || { userVessels: [] };

  return (
    <div className="pr-10 mb-2.5 gap-2 flex items-center text-center">
      <div className="text-md text-blackish">{getServingPriceLabel(serving, true)}</div>
      <div className="text-md leading-tight text-light-blackish">{resolveVesselName(serving.vessel, userVessels)}</div>
      <div className="text-md leading-tight text-light-blackish">{getServingSizeLabel(serving)}</div>
    </div>
  );
};
