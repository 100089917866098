import React from 'react';
import { CURRENCIES_ARRAY, LOCALE_MAP } from '../util/currencies';
import { Select } from './shared/inputs';

interface CurrencyDropdownProps {
  currency: string;
  setCurrency: (currency: string) => void;
  label?: string;
}

function getLocalizedCurrencyName(currencyCode: string) {
  try {
    const locale = LOCALE_MAP[currencyCode as keyof typeof LOCALE_MAP] || 'en-US';
    return new Intl.DisplayNames([locale], { type: 'currency' }).of(currencyCode) || currencyCode;
  } catch {
    return currencyCode;
  }
}

export function CurrencyDropdown({ currency, setCurrency, label = 'Currency' }: CurrencyDropdownProps) {
  return (
    <Select
      value={currency}
      options={CURRENCIES_ARRAY.map((value) => ({
        value: value.id,
        label: `${getLocalizedCurrencyName(value.id)} (${value.symbol})`
      }))}
      onChange={(value) => setCurrency(value as string)}
      label={label}
    />
  );
}
