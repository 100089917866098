import clsx from 'clsx';
import ReactGA from 'react-ga';
import { find, groupBy, sortBy } from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-apollo';

import { SessionContext } from '../../contexts/session';

import { Grid, makeStyles } from '@material-ui/core';
import { UserVesselsContext, UserVesselsProvider } from '../../contexts/userVessels';
import { getServingPriceLabel, getServingSizeLabel, resolveVesselName } from '../../util/lang';
import { getFilteredTapList, getTapLocation } from '../../util/tap';
import { sortServings } from '../../util/servings';
import { GET_BEVERAGE_CATEGORIES, GET_TAPS_BY_STATUS } from './queries';

import { useTranslation } from 'react-i18next';
import PourwalLogoAltImage from '../../images/pourwall-logo-alt.svg';

import MenuPrintDocument from './print/MenuPrintDocument';
import ClassicMenuPage from './print/ClassicMenuPage';
import VerticalMenuPage from './print/VerticalMenuPage';
import ColumnLayout from './print/ColumnLayout';
import ClassicHeader from './print/ClassicHeader';
import ClassicFooter from './print/ClassicFooter';
import { MENU_TYPE } from './print/enums';
import { usePrintConfigFromURLParams } from './print/utils';
import VerticalColumnComponent from './print/VerticalColumnComponent';
import VerticalHeader from './print/VerticalHeader';
import ClassicSessionHeader from './print/ClassicSessionHeader';
import ClassicTap from './print/ClassicTap';
import VerticalFooter from './print/VerticalFooter';

export const PrintTaps = () => {
  const config = usePrintConfigFromURLParams(window.location.search);
  switch (config.menuType) {
    case MENU_TYPE.CLASSIC:
      return <ClassicMenu />;
    case MENU_TYPE.VERTICAL:
      return <VerticalMenu />;
    default:
      return <DefaultPrintTaps />;
  }
};

export const VerticalMenu = () => {
  return (
    <MenuPrintDocument
      Page={({ config, columns, onColumnRef }) => (
        <VerticalMenuPage
          config={config}
          columns={columns}
          onColumnRef={onColumnRef}
          Header={VerticalHeader}
          Content={(props) => (
            <ColumnLayout {...props} ColumnComponent={({ data }) => <VerticalColumnComponent data={data} config={props.config} />} />
          )}
          Footer={(props) => (
            <VerticalFooter
              content={Object.values(props.config.footer).filter((value) => !!value)}
              config={{ textColor: props.config.theme.primaryText, separatorColor: props.config.theme.primary }}
            />
          )}
        />
      )}
    />
  );
};

export const ClassicMenu = () => {
  return (
    <MenuPrintDocument
      Page={({ config, columns, onColumnRef }) => (
        <ClassicMenuPage
          config={config}
          columns={columns}
          onColumnRef={onColumnRef}
          Header={ClassicHeader}
          Content={(props) => (
            <ColumnLayout
              {...props}
              ColumnComponent={({ data }) => (
                <div style={{ padding: '0 0px 0 0px' }}>
                  {data.map((entry, index) => {
                    if (!entry) {
                      return null;
                    }

                    if (entry.groupName) {
                      return (
                        <ClassicSessionHeader key={entry._id + entry.groupName} groupName={entry.groupName} theme={props.config.theme} />
                      );
                    }
                    const isPreviousItemAGroup = !!data[index - 1]?.groupName;
                    return (
                      <>
                        {index > 0 && !isPreviousItemAGroup && (
                          <div
                            style={{
                              width: '100%',
                              borderBottomWidth: 1,
                              borderColor: props.config.theme.dash,
                              borderStyle: 'dashed'
                            }}></div>
                        )}
                        <ClassicTap key={entry._id} tap={entry} config={props.config} />
                      </>
                    );
                  })}
                </div>
              )}
            />
          )}
          Footer={(props) => (
            <ClassicFooter
              content={Object.values(props.config.footer).filter((value) => !!value)}
              config={{ textColor: props.config.theme.primaryText, separatorColor: props.config.theme.primary }}
            />
          )}
        />
      )}
    />
  );
};

const isOverflown = ({ clientWidth, clientHeight, scrollWidth, scrollHeight }) => {
  return scrollHeight > clientHeight || scrollWidth > clientWidth;
};

export const DefaultPrintTaps = () => {
  const settings = useMemo(() => new URLSearchParams(window.location.search), []);
  const [
    title,
    groupTapsBy,
    columnCount,
    showDescription,
    showLocation,
    showDate,
    showHandle,
    sortTapsBy,
    showBreweryName,
    showServings,
    showServingSize,
    showServingCurrency,
    showLogo,
    tapStart,
    tapEnd,
    groupsOrder
  ] = [
    settings.get('title'),
    settings.get('groupBy'),
    settings.get('columns') ?? 2,
    settings.get('showDescription') === 'true',
    settings.get('showLocation') === 'true',
    settings.get('showDate') === 'true',
    settings.get('showHandle') === 'true',
    settings.get('sortBy'),
    settings.get('showBreweryName') === 'true',
    settings.get('showServings') === 'true',
    settings.get('showServingSize') === 'true',
    settings.get('showServingCurrency') === 'true',
    settings.get('showLogo') === 'true',
    settings.get('tapStart') ?? 1,
    settings.get('tapEnd') ?? 1,
    settings.get('groupsOrder').split(',') ?? []
  ];
  const { currentLocation, premium, account } = useContext(SessionContext);
  const { t } = useTranslation();
  const columnRefs = useRef([]);
  const [columns, setColumns] = useState([[]]);

  const { data: beverageCategoryData } = useQuery(GET_BEVERAGE_CATEGORIES, {
    fetchPolicy: 'cache-and-network'
  });

  const { data } = useQuery(GET_TAPS_BY_STATUS, {
    variables: { location: currentLocation?._id, statuses: ['ACTIVE'] }, // TODO: Get taps on deck?
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    if (data?.taps?.length > 0 && beverageCategoryData?.categories?.length > 0) {
      const filteredTapList = getFilteredTapList(tapStart, tapEnd, data.taps);

      let cs = [];
      if (groupTapsBy) {
        let groups = {};

        switch (groupTapsBy) {
          case 'category':
            groups = groupBy(filteredTapList, (i) => i?.beverage?.category || t('PrintedMenu.Other'));
            break;
          case 'brewery':
            groups = groupBy(filteredTapList, (i) => i?.beverage?.source?.name || t('PrintedMenu.Other'));
            break;
          case 'none':
            groups = groupBy(filteredTapList, () => '');
            break;
          case 'style':
          default: //Group by style
            groups = groupBy(filteredTapList, (i) => i?.beverage?.style || t('PrintedMenu.Other'));
            break;
        }

        let tapsByGroup = [];
        const entries = Object.entries(groups);
        const groupsOrdered = groupTapsBy !== 'none' ? groupsOrder?.map((position) => entries[position]) : entries;
        for (const [key, value] of groupsOrdered) {
          const sortedTaps = sortTaps(value, sortTapsBy);
          tapsByGroup = [...tapsByGroup, { groupName: key }, ...sortedTaps];
        }
        cs[0] = tapsByGroup;
      } else {
        cs[0] = sortTaps(filteredTapList, sortTapsBy);
      }
      setColumns(cs);

      // When everything is set, wait 2 secs to open the print dialog
      const timer = setTimeout(() => {
        window.print();

        const paramsObject = JSON.stringify(Object.fromEntries(settings.entries()));

        const gaLabel = `Account: ${account._id} Template: Default  Custom Params: ${paramsObject}`;

        ReactGA.event({
          category: 'print_start',
          action: 'print_start',
          label: gaLabel
        });
        if (typeof window.gtag === 'function') {
          window.gtag('event', 'print_start', {
            event_category: 'print_start',
            event_label: gaLabel
          });
        }
      }, 2000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account?._id, beverageCategoryData?.categories, data?.taps, groupTapsBy, settings, sortTapsBy, t, tapEnd, tapStart]);

  const sortTaps = (tapsList = [], sortingParam) => {
    switch (sortingParam) {
      case 'abv':
        return sortBy(tapsList, (t) => t.beverage?.abv);
      case 'style':
        return sortBy(tapsList, (t) => t.beverage?.style);
      case 'category':
        return sortBy(tapsList, (t) => t.beverage?.category);
      case 'breweryName':
        return sortBy(tapsList, (t) => t.beverage?.source?.name);
      case 'beverageName':
        return sortBy(tapsList, (t) => t.beverage?.name);
      case 'handle':
      default:
        return sortBy(tapsList, (t) => t.name);
    }
  };

  const theme = {
    title,
    columnCount,
    showDescription,
    showLocation,
    showDate,
    showHandle,
    showBreweryName,
    showServings,
    showServingSize,
    showServingCurrency,
    showLogo
  };

  useEffect(() => {
    for (let i = 0; i < columnRefs.current.length; i++) {
      let colRef = columnRefs.current[i];
      let taps = columns[i];

      if (isOverflown(colRef)) {
        let lastTap = taps.pop();
        let updatedColumns = [...columns];

        if (!updatedColumns[i + 1]) {
          updatedColumns[i + 1] = [];
        }
        updatedColumns[i + 1].unshift(lastTap);

        // see if the previous item is a group heading, and if so, move it over too so we don't have an orphan
        if (taps[taps.length - 1]?.groupName) {
          let group = taps.pop();
          updatedColumns[i + 1].unshift(group);
        }

        setColumns(updatedColumns);
      }
    }
  }, [columns]);

  const handleCreateColumnRef = (el) => {
    if (el) {
      if (!find(columnRefs.current, (c) => c === el)) {
        columnRefs.current.push(el);
      }
    }
  };

  let pages = [];
  for (let i = 0; i < columns.length; i++) {
    if (i % columnCount === 0) {
      pages.push([Array(columnCount).fill()]);
    }
    let p = pages[pages.length - 1];
    p[i % columnCount] = columns[i];
  }

  if (!premium) {
    return null;
  }

  return (
    <>
      {pages.map((page, i) => (
        <Page key={i} theme={theme} columns={page} handleCreateColumnRef={handleCreateColumnRef} />
      ))}
      <style
        dangerouslySetInnerHTML={{
          __html: `
                @page {
                    size: auto;
                    margin: 0;
                     /* change the margins as you want them to be. */
                }

                body {
                    background: white !important;
                    -webkit-print-color-adjust: exact !important;
                }
                @media print
                {
                }
            `
        }}></style>
    </>
  );
};

const Page = ({ theme, columns, handleCreateColumnRef }) => {
  const { i18n } = useTranslation();
  const classes = useStyles();
  const { columnCount, showDate, title, showLogo } = theme;
  const width = `${Math.floor(100 / columnCount)}%`;

  moment.locale(i18n.language);

  return (
    <UserVesselsProvider>
      <div className={classes.fullPage}>
        {showDate && <div className={classes.date}>{moment().format('ddd, MMM Do YYYY')}</div>}
        {title && <h1 className={classes.title}>{title}</h1>}
        <div className={clsx(classes.contentContainer, !showLogo && classes.contentWithoutLogo)}>
          {Array.from({ length: columnCount })?.map((_, i) => {
            const col = columns?.[i];
            if (!col) {
              return <div key={i} style={{ width }} />;
            }

            return (
              <div
                key={i}
                className={clsx(classes.contentColumn, !showLogo && classes.columnWithoutLogo)}
                ref={handleCreateColumnRef}
                style={{ width }}>
                {col.map((entry) => {
                  if (entry.groupName) {
                    return (
                      <div key={entry.groupName} className={classes.columnGroup}>
                        {entry.groupName}
                      </div>
                    );
                  }
                  if (!entry?._id) {
                    return null;
                  }
                  return <Tap key={entry._id} tap={entry} theme={theme} />;
                })}
              </div>
            );
          })}
        </div>
        {showLogo && (
          <div className={classes.logoContainer}>
            <img src={PourwalLogoAltImage} alt="POURWALL" />
          </div>
        )}
      </div>
    </UserVesselsProvider>
  );
};

const Serving = ({ serving, theme = {} }) => {
  const { showServingCurrency, showServingSize, showServings } = theme;
  const { userVessels } = useContext(UserVesselsContext);
  const classes = useStyles();

  if (!showServings) {
    return null;
  }

  return (
    <Grid item key={serving._id} className={classes.servingContainer}>
      <div className={classes.servingPrice} style={{ color: theme.foregroundColor2 }}>
        {getServingPriceLabel(serving, showServingCurrency)}
      </div>
      <div className={classes.servingSize} style={{ color: theme.foregroundColor }}>
        <div>
          {resolveVesselName(serving.vessel, userVessels)} {showServingSize ? `(${getServingSizeLabel(serving)})` : ''}
        </div>
      </div>
    </Grid>
  );
};

const Tap = ({ tap, theme }) => {
  const classes = useStyles();
  const tapLocation = getTapLocation(tap);
  const { name } = tap.beverage?.source || {};
  const { showHandle, showBreweryName, showLocation } = theme;

  return (
    <div className={classes.tapItem}>
      {showHandle && (
        <span className={classes.tapHandle} style={{ color: theme.foregroundColor }}>
          {tap.name}
        </span>
      )}

      <div className={classes.tapMeta}>
        <div className={classes.tapMetaPrimary}>
          <div className="item-name">
            {showBreweryName && name ? name + ' -' : ''} {tap.beverage?.name}
          </div>
        </div>

        <div className={classes.tapMetaSecondary} style={{ color: theme.foregroundColor }}>
          <span className="style">{tap.beverage?.style}</span>
          {showLocation && tapLocation && (
            <>
              <span className="divider"> - </span>
              <span className="style">{tapLocation}</span>
            </>
          )}
          {tap.showABV !== false && (
            <>
              <span className="divider"> - </span>
              <span className="abv">{isNaN(tap.beverage?.abv) ? '?' : tap.beverage?.abv}% ABV</span>
            </>
          )}
          {tap.showIBU === true && (
            <>
              <span className="divider"> - </span>
              <span className="abv">{isNaN(tap.beverage?.ibu) ? '?' : tap.beverage?.ibu} IBUs</span>
            </>
          )}
        </div>

        <Grid container className="item-meta-cost">
          <Grid container item xs={12} spacing={2} style={{ textAlign: 'center' }}>
            {sortServings(tap.servings).map((s, i) => (
              <Serving key={i} serving={s} theme={theme} />
            ))}
          </Grid>
        </Grid>

        {theme.showDescription && <div className={classes.tapMetaDescription}>{tap.beverage?.description}</div>}
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  pageHeading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 20,
    fontWeight: 'bold',
    paddingBottom: 10
  },

  fullPage: {
    height: '279.4mm',
    width: '215.9mm',
    // border: '1px solid red',
    padding: 20,
    position: 'relative',

    fontSize: '8pt'
  },
  date: {
    position: 'absolute',
    top: 20,
    right: 20
  },
  title: {
    minHeight: 40,
    maxHeight: 80,
    maxWidth: 590,
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 'bold',
    margin: '0 auto'
  },
  contentContainer: {
    height: 'calc(279.4mm - 40px - 40px - 40px - 10px)', // 40px title, 40px padding, 40px logo, 10px border
    // border: '1px solid blue',
    display: 'flex',
    justifyContent: 'space-between'
  },
  contentWithoutLogo: {
    height: 'calc(279.4mm - 40px - 40px - 10px)' // 40px title, 40px padding, 10px border
  },
  contentColumn: {
    display: 'inline-block',
    //width: `calc((215.9mm - 40px - 20px) / 2)`,
    height: `calc(279.4mm - 40px - 40px - 40px - 10px - 10px)`, // 40px title, 40px padding, 40px logo, 10px border, 10px padding
    // border: '1px solid green',
    padding: 3
  },
  columnWithoutLogo: {
    height: `calc(279.4mm - 40px - 40px - 10px - 10px)` // 40px title, 40px padding, 10px border, 10px padding
  },
  logoContainer: {
    height: '40px',
    // border: '1px solid black',
    '& img': {
      margin: '0px auto',
      height: '40px'
    }
  },
  columnGroup: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#efefef',
    padding: 10,
    height: 40,
    fontWeight: 'bold'
  },
  tapItem: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px 0 20px 0',

    // not sure if this does anything
    pageBreakInside: 'avoid'
  },

  tapHandle: {
    width: 30,
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center'
  },
  tapMeta: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  tapMetaPrimary: {
    display: 'flex',
    fontWeight: 'bold',
    flexDirection: 'row'
  },
  tapMetaDescription: {
    paddingLeft: 5,
    paddingTop: 5,
    fontSize: '0.8em'
  },

  servingContainer: {
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  servingPrice: {
    fontWeight: 'bold',
    '& span': {
      fontWeight: 'normal'
    }
  },
  servingSize: {
    marginRight: 10
  }
}));
