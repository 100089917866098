import { sortBy } from 'lodash';
import { Serving } from '../types';

export const sortServings = (servings: Serving[]): Serving[] => {
  const allHaveOrder = servings.every((s) => typeof s.order === 'number');
  const noneHaveOrder = servings.every((s) => typeof s.order !== 'number');

  if (allHaveOrder) {
    return sortBy(servings, (s) => s.order);
  }

  if (noneHaveOrder) {
    return sortBy(servings, (s) => s.size);
  }

  return sortBy(servings, (s) => s.size);
};
