import React, { useState, useContext } from 'react';
import { find, without, sortBy } from 'lodash';
import shortid from 'shortid';
import { useTranslation } from 'react-i18next';

import { SessionContext } from '../../contexts/session';

import { FeedbackBar } from '../../components/FeedbackBar';
import { NewBeverageTypeSelection } from './beverages/NewBeverageSelection';

import { CREATE_TAP, UPDATE_TAP, DELETE_TAP, SIGN_FILE_FOR_UPLOAD } from './mutations';
import { cleanCachedObjectForMutation, spreadMutations, getMessageFromError } from '../../util/graphql';
import FileUpload from '../../components/FileUpload';
import { checkFileSize, MAX_TAP_IMAGE_SIZE_BYTES, uploadToAws } from '../../util/files';
import { GET_BEVERAGE_CATEGORIES } from './queries';
import { UserVesselsContext } from '../../contexts/userVessels';
import { RadioGroup } from '../../components/shared/RadioGroup';
import { Tab } from '../../components/shared/Tab';
import { Tabs } from '../../components/shared/Tabs';
import { Button } from '../../components/shared/Button';
import { useMutation, useQuery } from 'react-apollo';
import { ArrowBack } from '@material-ui/icons';
import { TapEditServings } from './servings/TapEditServings';
import ArrowCurvedUpIcon from '../../images/icon-arrow-curved-up';
import { Input, AutoComplete, TextArea } from '../../components/shared/inputs';

export const TapEditForm = ({ tap, onClose }) => {
  const { t } = useTranslation();
  const { user, currentLocation } = useContext(SessionContext);
  const { userVessels, createUserVessel } = useContext(UserVesselsContext);
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState(tap.name);
  const [type, setType] = useState(tap.beverage?.type);
  const [list] = useState(tap.list || 'Active');

  // beverage fields
  const [beverageName, setBeverageName] = useState(tap.beverage?.name || '');
  const [beverageStyle, setBeverageStyle] = useState(tap.beverage?.style || '');
  const [beverageStatus, setBeverageStatus] = useState(tap.status || 'ACTIVE');
  const [beverageCategory, setBeverageCategory] = useState(tap.beverage?.category || '');
  const [beverageAbv, setBeverageAbv] = useState(tap.beverage?.abv);
  const [beverageIBU, setBeverageIBU] = useState(tap.beverage?.ibu);
  const [beverageDescription, setBeverageDescription] = useState(tap.beverage?.description || '');
  const [beverageRateBeerRating, setBeverageRateBeerRating] = useState(tap.beverage?.rateBeerRating);

  const [beverageSourceName, setBeverageSourceName] = useState(tap.beverage?.source?.name || '');
  const [beverageSourceLocality, setBeverageSourceLocality] = useState(tap.beverage?.source?.location?.locality || '');
  const [beverageSourceRegion, setBeverageSourceRegion] = useState(tap.beverage?.source?.location?.region || '');
  const [beverageSourceCountry, setBeverageSourceCountry] = useState(tap.beverage?.source?.location?.country || '');
  const [beverageImages, setBeverageImages] = useState(tap.beverage?.images || []);

  const [beverageImagePreviewUrl, setBeverageImagePreviewUrl] = useState();
  const [beverageImageFile, setBeverageImageFile] = useState();
  const [beverageImageFileSignedUploadUrl, setBeverageImageFileSignedUploadUrl] = useState();

  const [servings, setServings] = useState(tap.servings || []);
  const [editServing, setEditServing] = useState(null);
  const [copySergingsDialogOpen, setCopyServingsDialogOpen] = useState(false);
  const [showABV, setShowABV] = useState(!!tap.beverage?.abv);
  const [showIBU, setShowIBU] = useState(!!tap.beverage?.ibu);
  const [validating, setValidating] = useState(false);

  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (index) => {
    setSelectedTab(index);
  };

  const [[createTap, saveTap, deleteTap, signFile], { loading: mutationLoading }] = spreadMutations([
    useMutation(CREATE_TAP),
    useMutation(UPDATE_TAP),
    useMutation(DELETE_TAP),
    useMutation(SIGN_FILE_FOR_UPLOAD)
  ]);

  const { data: beverageCategoryData } = useQuery(GET_BEVERAGE_CATEGORIES);
  const beverageCategories = sortBy(beverageCategoryData?.categories || [], (c) => c.name);

  const handleSelectBeverage = (beverage) => {
    setType(beverage.type);
    setBeverageName(beverage.name);
    setBeverageStyle(beverage.style);
    setBeverageCategory(beverage.category);
    setBeverageAbv(beverage.abv);
    setBeverageIBU(beverage.ibu);
    setBeverageDescription(beverage.description);
    setBeverageRateBeerRating(beverage.rateBeerRating);
    setBeverageSourceName(beverage.source?.name);
    setBeverageSourceLocality(beverage.source?.location?.locality);
    setBeverageSourceRegion(beverage.source?.location?.region);
    setBeverageSourceCountry(beverage.source?.location?.country);
    setBeverageImages(beverage.images || []);
    setShowABV(!!beverage.abv);
    setShowIBU(!!beverage.ibu);
  };

  const handleBeverageImageSelection = async (file, previewUrl) => {
    //The line below is a workaround to show the error messages everytime a file is chosen.
    setErrorMessage('');
    const fileSizeError = checkFileSize(file, MAX_TAP_IMAGE_SIZE_BYTES);
    if (fileSizeError) {
      setErrorMessage(fileSizeError.message);
    } else {
      setBeverageImagePreviewUrl(previewUrl);
      const variables = {
        locationName: currentLocation?.shortName,
        fileName: file.name,
        fileType: file.type
      };
      const { data, error } = await signFile({ variables });
      if (error) {
        setErrorMessage(error);
      }
      if (data?.signFile) {
        setBeverageImageFile(file);
        setBeverageImageFileSignedUploadUrl(data.signFile.signedUrl);

        let images = [...beverageImages];
        let match = find(images, (i) => i.size === 'medium');
        if (!match) {
          match = { size: 'medium' };
          images.push(match);
        }
        match.url = data.signFile.resourceUrl;
        setBeverageImages(images);
      }
    }
  };

  const handleAddServing = () => {
    let serving = { key: shortid.generate(), order: servings.length };
    setEditServing(serving);
  };

  const handleSaveServing = (serving) => {
    if (serving.isNew) {
      createUserVessel({
        name: serving.vessel,
        size: serving.size
      });
    }
    delete serving.isNew;
    let allServings = [...servings];

    let matchedServing = find(allServings, (p) => p.key === serving.key);

    if (!matchedServing) {
      serving.order = allServings.length;
      allServings.push(serving);
    } else {
      serving.order = matchedServing.order;
      Object.assign(matchedServing, serving);
    }

    setServings(allServings);
    setEditServing(null);
  };

  const handleCopyServings = (copied) => {
    const copiedServings = copied.map((s) => {
      if (s.isNew) {
        createUserVessel({
          name: s.vessel,
          size: s.size,
          order: s.order
        });
      }
      delete s.isNew;
      return s;
    });
    setServings(copiedServings);
  };

  const handleDeleteServing = () => {
    if (editServing && editServing.key) {
      let allServings = [...servings];
      let matchedServing = find(allServings, (p) => p.key === editServing.key);
      if (matchedServing) {
        allServings = without(allServings, matchedServing);
        setServings(allServings);
      }
    }
    setEditServing(null);
  };

  const handleDelete = async () => {
    await deleteTap({ variables: { id: tap._id } });
    onClose();
  };

  const handleSave = async () => {
    setValidating(true);
    if (!beverageName || name === '') {
      selectedTab !== 0 && setSelectedTab(0);
      return;
    }
    if (beverageImageFile && beverageImageFileSignedUploadUrl) {
      setLoading(true);
      try {
        await uploadToAws(beverageImageFileSignedUploadUrl, beverageImageFile);
      } catch (e) {
        setErrorMessage(e);
        setLoading(false);
      }
    }

    let location = {
      locality: beverageSourceLocality,
      region: beverageSourceRegion,
      country: beverageSourceCountry
    };
    let beverage = Object.assign(
      { ...tap.beverage },
      {
        name: beverageName,
        type,
        style: beverageStyle,
        category: beverageCategory,
        abv: Number(beverageAbv),
        ibu: Number(beverageIBU),
        description: beverageDescription,
        rateBeerRating: beverageRateBeerRating,
        source: { name: beverageSourceName, location },
        images: beverageImages
      }
    );
    let cleanTap = cleanCachedObjectForMutation({
      ...tap,
      status: beverageStatus,
      name: Number(name),
      list,
      beverage,
      showABV,
      showIBU,
      servings
    });

    cleanTap = Object.assign({ createdBy: user._id, location: currentLocation?._id }, cleanTap);

    try {
      if (cleanTap._id) {
        await saveTap({ variables: { tap: cleanTap } });
      } else {
        await createTap({ variables: { tap: cleanTap } });
      }
      onClose();
    } catch (e) {
      setErrorMessage(getMessageFromError(e));
      console.log(e);
    }
  };

  let typeName = type === 'Other' ? 'Beverage' : type;
  let sourceType = type === 'Beer' ? 'Brewery' : type === 'Wine' ? 'Winery' : type === 'Cider' ? 'Cidery' : 'Maker';

  let imageUrl = find(beverageImages, (i) => i.size === 'medium')?.url;

  return (
    <div className="font-public-sans">
      <FeedbackBar message={errorMessage} />

      {!type && (
        <div>
          <div className="ml-4 mb-3 flex items-center">
            <ArrowBack className="cursor-pointer" onClick={() => onClose()} />
          </div>
          <NewBeverageTypeSelection onSelect={handleSelectBeverage} />
        </div>
      )}

      {type && (
        <>
          <Tabs
            leftIcon={<ArrowBack className="cursor-pointer self-center" />}
            onLeftIconClick={() => onClose()}
            selectedTab={selectedTab}
            onSelectedTabChange={handleTabChange}>
            <Tab label={t('TapForm.Basics')}>
              <div className="col-span-2 sm:col-span-3 md:col-span-4 flex px-4 gap-2 py-3 border-b border-gray-200 bg-light-green text-dark-green justify-center text-md items-center text-center">
                <ArrowCurvedUpIcon className="hidden sm:block" />
                {String(t('TapForm.HereToAddServings'))}
              </div>
              <div className="p-4 md:p-8 flex flex-col lg:grid lg:grid-cols-12 gap-x-4 gap-y-6 lg:items-end">
                <label className="col-span-12 uppercase font-bold text-dark-grey">{t('TapForm.BeverageSection')}</label>
                <RadioGroup
                  label={t('TapForm.BeverageStatus')}
                  options={[
                    { label: t('TapForm.BeverageActive'), value: 'ACTIVE' },
                    { label: t('TapForm.BeverageOnDeck'), value: 'ON_DECK' },
                    { label: t('TapForm.BeverageInactive'), value: 'INACTIVE' }
                  ]}
                  value={beverageStatus}
                  onChange={setBeverageStatus}
                  className="w-full col-span-12"
                />
                <Input
                  label={t('TapForm.BeverageName', { typeName })}
                  value={beverageName}
                  onChange={setBeverageName}
                  className="w-full"
                  containerClassName="col-span-5"
                  error={validating && !beverageName}
                />
                <Input
                  label={t('TapForm.BeverageSource', {
                    sourceType
                  })}
                  value={beverageSourceName}
                  onChange={setBeverageSourceName}
                  className="w-full"
                  containerClassName="col-span-5"
                />
                <Input
                  label={t('TapForm.TapNo')}
                  value={name}
                  type="number"
                  onChange={setName}
                  className="w-full"
                  containerClassName="col-span-2"
                  error={validating && name === ''}
                  cantBeEmpty
                />
                <Input
                  label={t('TapForm.BeverageStyle', {
                    typeName
                  })}
                  value={beverageStyle}
                  onChange={setBeverageStyle}
                  className="w-full"
                  containerClassName="col-span-6 mt-4"
                />
                <AutoComplete
                  label={t('TapForm.BeverageCategory', {
                    typeName
                  })}
                  options={beverageCategories.map((option) => ({ label: option.name, value: option.name }))}
                  value={beverageCategory}
                  onChange={setBeverageCategory}
                  className="w-full"
                  containerClassName="col-span-6 mt-4"
                />
                <TextArea
                  label={t('TapForm.BeverageDescription', {
                    typeName
                  })}
                  value={beverageDescription}
                  onChange={setBeverageDescription}
                  className="w-full"
                  containerClassName="col-span-12"
                />
                <RadioGroup
                  label={t('TapForm.DisplayABV')}
                  value={showABV}
                  onChange={setShowABV}
                  options={[
                    { label: 'No', value: 'false' },
                    { label: 'Yes', value: 'true' }
                  ]}
                  className="w-full col-span-2"
                />
                <Input
                  label={t('TapForm.ABV')}
                  type="number"
                  step={0.1}
                  numberMode="float"
                  value={beverageAbv}
                  disabled={!showABV}
                  onChange={setBeverageAbv}
                  className="w-full"
                  containerClassName="col-span-2"
                />

                <RadioGroup
                  label={t('TapForm.DisplayIBU')}
                  value={showIBU}
                  onChange={setShowIBU}
                  options={[
                    { label: 'No', value: 'false' },
                    { label: 'Yes', value: 'true' }
                  ]}
                  className="w-full col-span-2"
                />
                <Input
                  label={t('TapForm.IBU')}
                  type="number"
                  step={0.1}
                  numberMode="float"
                  value={beverageIBU}
                  disabled={!showIBU}
                  onChange={setBeverageIBU}
                  className="w-full"
                  containerClassName="col-span-2"
                />

                <div className="col-span-4 flex items-end">
                  <div className="flex flex-col items-center mr-4 gap-2">
                    <label className="text-xs text-light-blackish">Tap Image</label>

                    {beverageImagePreviewUrl ? (
                      <div
                        className="w-12 h-12 rounded-full bg-center bg-cover inline-block"
                        style={{ backgroundImage: `url(${beverageImagePreviewUrl})` }}
                      />
                    ) : !beverageImagePreviewUrl && imageUrl ? (
                      <div
                        className="w-12 h-12 rounded-full bg-center bg-cover inline-block"
                        style={{ backgroundImage: `url("${imageUrl}")` }}
                      />
                    ) : (
                      <div className="w-12 h-12 border-1 border-light-grey border-dashed rounded-full bg-center bg-cover inline-block" />
                    )}
                  </div>
                  <FileUpload
                    labelClassName="flex flex-col gap-[5px]"
                    onFileSelected={handleBeverageImageSelection}
                    maxFileSize={MAX_TAP_IMAGE_SIZE_BYTES}
                    infoText={t('TapForm.TapImageInfo')}
                  />
                </div>
                <label className="col-span-12 uppercase font-bold text-dark-grey mt-4">{t('TapForm.BrewerySection', { sourceType })}</label>
                <Input
                  label={t('TapForm.BeverageCity', {
                    sourceType
                  })}
                  value={beverageSourceLocality}
                  onChange={setBeverageSourceLocality}
                  className="w-full"
                  containerClassName="col-span-4"
                />
                <Input
                  label={t('TapForm.BeverageState', {
                    sourceType
                  })}
                  value={beverageSourceRegion}
                  onChange={setBeverageSourceRegion}
                  className="w-full"
                  containerClassName="col-span-2"
                />
                <Input
                  label={t('TapForm.BeverageCountry', {
                    sourceType
                  })}
                  value={beverageSourceCountry}
                  onChange={setBeverageSourceCountry}
                  className="w-full"
                  containerClassName="col-span-4"
                />
              </div>
            </Tab>
            <Tab className="relative" label={`${t('TapForm.Servings')} (${servings.length})`}>
              <TapEditServings
                servings={servings}
                editServing={editServing}
                userVessels={userVessels}
                copySergingsDialogOpen={copySergingsDialogOpen}
                tap={tap}
                onAddServing={handleAddServing}
                onEditServing={setEditServing}
                onCancelEdit={() => setEditServing(null)}
                onDeleteServing={handleDeleteServing}
                onSaveServing={handleSaveServing}
                onCopyServings={handleCopyServings}
                onToggleCopyDialog={() => setCopyServingsDialogOpen(!copySergingsDialogOpen)}
                setServings={setServings}
              />
            </Tab>
          </Tabs>

          <div className="border-t gap-2 p-4 flex justify-end items-center">
            <Button variant="destructive" disabled={!tap._id} onClick={handleDelete}>
              {t('TapForm.Delete')}
            </Button>
            <Button variant="primary" color="primary" disabled={loading || mutationLoading} onClick={handleSave}>
              {t('TapForm.Save')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
